module.exports = {
    description: 'Center for Humanities Computing Aarhus',
    domain: 'chcaa.au.dk',
    routing: { // routes are relative to client/src/router/routes/
        Home: {
            path: '/',
            name: 'home',
            component: () => import("@/components/site/home/Home.vue"),
            meta: {
                plugins: ['documents', 'texts', 'teasers']
            }
        },
        About: {
            isMenuRoute: true,
            path: '/about',
            name: 'about',
            component: () => import("@/components/site/about/About.vue"),
            meta: {
                plugins: ['documents', 'texts', 'person', 'zoomableImage']
            },
        },
        Courses: {
            isMenuRoute: true,
            path: '/courses',
            name: 'courses',
            component: () => import("@/components/learningMaterials/Courses.vue"),
            meta: {
                plugins: ['courses', 'zoomableImage']
            }
        },
        Course: {
            name: 'course',
            path: '/courses/:courseName',
            component: () => import(/* webpackChunkName: "course" */ '@/components/learningMaterials/course/CourseContent.vue'),
            props: true,
            meta: {
                plugins: ['courses', 'zoomableImage']
            }
        },
        Module: {
            name: 'courseModule',
            path: '/courses/:courseName/modules/:moduleName',
            component: () => import(/* webpackChunkName: "course" */ '@/components/learningMaterials/course/courseModule/ModuleContent.vue'),
            props: true,
            meta: {
                plugins: ['modules', 'zoomableImage']
            }
        },
        News: {
            isMenuRoute: true,
            path: '/news',
            name: 'news',
            component: () => import("@/components/site/news/News.vue"),
            meta: {
                plugins: ['news']
            },
        },
        Testimonials: {
            isMenuRoute: true,
            path: '/testimonials',
            name: 'testimonials',
            component: () => import("@/components/site/testimonials/Testimonials.vue"),
            meta: {
                plugins: ['testimonials']
            },
        },
        Services: {
            isMenuRoute: true,
            path: '/services',
            name: 'services',
            component: () => import("@/components/site/centerServices/Services.vue"),
            meta: {
                plugins: ['services']
            },
        },
        UCloud: {
            isMenuRoute: true,
            path: '/uCloud',
            name: 'UCloud',
            component: () => import("@/components/CHCAA/UCloud.vue"),
            meta: {
                plugins: ['CHCAAuCloudServices']
            },
        },

    },
    colors: {
        primaryBackgroundColor: '#003d73',
        primaryColor: '$smokeWhite',
    },
    clientPort: 8080,
    serverPort: 3080,
    emailSenderAddress: 'none'
}
