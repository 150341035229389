const CHCAA = require('./configurations/CHCAA.js')
const IHPC = require('./configurations/IHPC.js')
const CEDHAR = require('./configurations/CEDHAR.js')

const services = {
  CHCAA,
  IHPC,
  CEDHAR
}

const SERVICE_ACRONYM = 'CEDHAR' // <<<<<<<<<<<<<<<<<<< Select config file here to switch between applications
//                      ^^^^^^^^

const service = services[SERVICE_ACRONYM]
const apiPath = '/api/v1'

const inProduction = process?.env.NODE_ENV !== 'development'

const protocol = inProduction ? 'https://' : 'http://'
const baseUrl = inProduction ? service.domain : 'localhost'
const getUrlPort = port => (inProduction || port === 80) ? '' : `:${port}`

const serverUrl = protocol + baseUrl + getUrlPort(service.serverPort)
const clientUrl = protocol + baseUrl + getUrlPort(service.clientPort)

const getServicePlugins = service => {
  const plugins = new Set(['texts', 'documents', 'documentArrays'])
  if (service.routing) {
    const routingTable = service.routing
    Object.values(routingTable).forEach(routes => {
      const isNested = !routes.name
      if (isNested) {
        Object.values(routes).forEach(route => {
          if (route.meta?.plugins?.length) route.meta.plugins.forEach(plugin => plugins.add(plugin))
        })
      } else {
        routes.meta.plugins.forEach(plugin => plugins.add(plugin))
      }
    })
  } else {
    service.plugins.forEach(plugin => plugins.add(plugin))
  }
  if (plugins.has('courses')) {
    plugins.add('courseModules')
    plugins.add('paragraphs')
  }
  return Array.from(plugins)
}
const PLUGINS = getServicePlugins(service)

const config = {
  SERVICE_ACRONYM,
  SERVICE_DESCRIPTION: service.description,

  JWT_TTL: '30m',  // s: seconds, m: minutes, ...
  JWT_GRACE_PERIOD: '30', // seconds

  serverUrl,
  clientUrl,

  PROCESS_PORT: service.serverPort,

  sitePath: '',
  ROUTING: service.routing,
  PLUGINS,
  PARTNERS: service.partners,
  PRIMARY_BACKGROUND_COLOR: service.colors.primaryBackgroundColor,
  PRIMARY_COLOR: service.colors.primaryColor,
  MEDIA_REPO_ORGANIZATION: 'centre-for-humanities-computing',
  MEDIA_REPO_NAME: 'websiteMedia',

  DEBUG_SECURITY: false,
  DEBUG_MIDDLEWARE: false,
  DEBUG_CONTROLLER: false,
  DEBUG_ACTIONS: false,
  debugMapParams: false,
  debugMiddleware: false,
  debugFlattenAttributesIntoContext: false,

  apiPath,
  EMAIL_SENDER_ADDRESS: service.emailSenderAddress,

  productionOriginPattern: '^.*$' // permitted client request origins (CORS header generation)
}
module.exports = config

