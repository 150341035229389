module.exports = {
    description: 'Center for Digital History Aarhus',
    domain: 'cedhar.au.dk',
    routing: { // routes are relative to client/src/router/routes/
        Home: {
            path: '/',
            name: 'home',
            component: () => import("@/components/CEDHAR/CEDHARHome.vue"),
            meta: {
                plugins: ['documents', 'texts', 'teasers']
            }
        },
        About: {
            isMenuRoute: true,
            path: '/about',
            name: 'about',
            component: () => import("@/components/site/about/About.vue"),
            meta: {
                plugins: ['documents', 'texts', 'person', 'zoomableImage']
            },
        },
        News: {
            isMenuRoute: true,
            path: '/news',
            name: 'news',
            component: () => import("@/components/site/news/News.vue"),
            meta: {
                plugins: ['news']
            },
        },
        Projects: {
            isMenuRoute: true,
            path: '/projects',
            name: 'projects',
            component: () => import("@/components/site/projects/Projects.vue"),
            meta: {
                plugins: ['projects', 'zoomableImage'],
                collectionName: 'projects'
            },
        },
        Resources: {
            isMenuRoute: true,
            path: '/resources',
            name: 'resources',
            component: () => import("@/components/site/resources/Resources.vue"),
            meta: {
                plugins: ['cedharResource', 'zoomableImage']
            },
        },
    },
    colors: {
        primaryBackgroundColor: '#bd764e',
        primaryColor: 'white',
    },
    clientPort: 8083,
    serverPort: 3083,
    emailSenderAddress: 'none'
}
