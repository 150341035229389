module.exports = {
  description: 'Interactive High Performance Computing',
  domain: 'interactivehpc.dk',
  routing: { // routes are relative to client/src/router/routes/
    old: {
      oldNews: {
        path: '/old/news',
        name: 'oldNews',
        component: () => import("@/components/site/news/News.vue"),
        meta: {
          plugins: ['documents', 'texts', 'news']
        }
      },
      oldAbout: {
        path: '/old/about',
        name: 'oldAbout',
        component: () => import("@/components/site/about/About.vue"),
        meta: {
          plugins: ['documents', 'texts', 'person']
        }
      },
      oldTestimonials: {
        path: '/old/testimonials',
        name: 'oldTestimonials',
        component: () => import("@/components/site/testimonials/Testimonials.vue"),
        meta: {
          plugins: ['documents', 'texts', 'testimonials']
        }
      },
      oldResources: {
        path: '/old/resources',
        name: 'oldResources',
        component: () => import("@/components/site/resources/Resources.vue"),
        meta: {
          plugins: ['resources']
        }
      },
    },
    Home: {
      path: '/',
      name: 'home',
      component: () => import("@/components/IHPC/IHPCHome.vue"),
      meta: {
        plugins: ['teasers', 'IHPCNews']
      }
    },
    About: {
      isMenuRoute: true,
      'IHPC Background': {
        path: '/IHPCBackground',
        name: 'IHPCBackground',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'IHPCBackground'
        }),
        meta: {
          plugins: ['documents']
        }
      },
      'Members and Partners': {
        path: '/membersAndPartners',
        name: 'membersAndPartners',
        component: () => import("@/components/site/ComponentPage.vue"),
        props: () => ({
          collectionName: 'person',
          componentName: 'People',
          removeBodyPaddingLeft: true
        }),
        meta: {
          plugins: ['documents', 'person']
        }
      },
      'Front Offices': {
        path: '/frontOffices',
        name: 'frontOffices',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'frontOffices'
        }),
        meta: {
          plugins: ['documents']
        }
      },
    },
    News: {
      isMenuRoute: true,
      'Stories and Events': {
        path: '/storiesAndEvents',
        name: 'storiesAndEvents',
        component: () => import("@/components/IHPC/IHPCNews.vue"),
        props: () => ({
          collectionName: 'IHPCNews',
          documentName: 'StoriesAndEvents'
        }),
        meta: {
          plugins: ['documents', 'IHPCNews', 'news']
        }
      },
      'Testimonials': {
        path: '/testimonials',
        name: 'testimonials',
        component: () => import("@/components/site/testimonials/Testimonials.vue"),
        meta: {
          plugins: ['testimonials', 'zoomableImage']
        }
      },
      'Publications': {
        path: '/publications',
        name: 'publications',
        component: () => import("@/components/site/publications/Publications.vue"),
        props: () => ({
          collectionName: 'texts',
          documentId: 'publications'
        }),
        meta: {
          plugins: ['documents']
        }
      },
    },
    LocalNewsItem: {
      path: '/localNews/:localNewsitemAlias',
      name: 'localNewsItem',
      component: () => import("@/components/IHPC/IHPCLocalNewsItem.vue"),
      props: route => ({
        collectionName: 'IHPCNews',
        localNewsitemAlias: route.params.localNewsitemAlias
      }),
      meta: {
        plugins: ['documents', 'IHPCNews']
      }
    },
    UCloud: {
      isMenuRoute: true,
      'About UCloud': {
        path: '/UCloud',
        name: 'UCloud',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'UCloud'
        }),
        meta: {
          plugins: ['documents']
        }
      },
      'Tutorials': {
        path: '/tutorials',
        name: 'tutorials',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'Tutorials'
        }),
        meta: {
          plugins: ['documents']
        }
      },
    },
    'Secure Platform': {
      path: '/securePlatform',
      name: 'securePlatform',
      component: () => import("@/components/site/Page.vue"),
      props: () => ({
        collectionName: 'texts',
        documentName: 'SecurePlatform'
      }),
      meta: {
        plugins: ['documents']
      }
    },
    'Data Analytics': {
      path: '/dataAnalytics',
      name: 'dataAnalytics',
      component: () => import("@/components/site/Page.vue"),
      props: () => ({
        collectionName: 'texts',
        documentName: 'DataAnalytics'
      }),
      meta: {
        plugins: ['documents']
      }
    },
    'Share and Collaborate': {
      path: '/shareAndCollaborate',
      name: 'shareAndCollaborate',
      component: () => import("@/components/site/Page.vue"),
      props: () => ({
        collectionName: 'texts',
        documentName: 'ShareAndCollaborate'
      }),
      meta: {
        plugins: ['documents']
      }
    },
    'Private Cloud': {
      path: '/privateCloud',
      name: 'privateCloud',
      component: () => import("@/components/site/Page.vue"),
      props: () => ({
        collectionName: 'texts',
        documentName: 'PrivateCloud'
      }),
      meta: {
        plugins: ['documents']
      }
    },
    'Advanced Services': {
      isMenuRoute: true,
      'Advanced IHPC Services': {
        path: '/advancedServices',
        name: 'advancedServices',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'AdvancedServices'
        }),
        meta: {
          plugins: ['documents', 'centerServices', 'zoomableImage']
        }
      },
      'Research Support': {
        path: '/researchSupport',
        name: 'researchSupport',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'ResearchSupport'
        }),
        meta: {
          plugins: ['centerServices', 'zoomableImage']
        }
      },
      'Data Management': {
        path: '/dataManagement',
        name: 'dataManagement',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'DataManagement'
        }),
        meta: {
          plugins: ['documents', 'centerServices', 'zoomableImage']
        }
      },
      'Training and Development': {
        path: '/trainingAndDevelopment',
        name: 'trainingAndDevelopment',
        component: () => import("@/components/site/Page.vue"),
        props: () => ({
          collectionName: 'texts',
          documentName: 'TrainingAndDevelopment'
        }),
        meta: {
          plugins: ['centerServices', 'zoomableImage']
        }
      },
    },
  },
  partners: ['aau', 'au', 'deic', 'sdu'],
  colors: {
    primaryBackgroundColor: '#1a65cf',
    primaryColor: 'white',
    babyBlue: '#1a65cf',
    darkBlue: '#a3c5f5',
    graphicsRed: '#ff0007',
    graphicsDarkBlue: '#005DFF',
    graphicsPurple: '#6B00E8',
    graphicsPink: '#F22AFF',
    graphicsLightBlue: '#D4DFFF',
  },
  clientPort: 8089,
  serverPort: 3089,
  emailSenderAddress: 'none'
}
